import { useState } from 'react';
import './App.css';

import RabbitHoleLiving1 from './assets/images/rabbit_hole_living_1.jpeg';
import DarkLands1 from './assets/images/dark_lands_1.jpeg';
import MisterB1 from './assets/images/mister_b_1.jpg';

import { useLayoutEffect } from 'react';
import Carousel from './Carousel';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const projects = [
    { title: "Rabbit Hole", image: RabbitHoleLiving1 },
    { title: "Dark Lands", image: DarkLands1 },
    { title: "Mister B", image: MisterB1 },
    { title: "More soon...", image: DarkLands1 },
  ]

  useLayoutEffect(() => {
    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const isTop = scrollTop === 0;
        setIsScrolled(!isTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    try {
        const response = await fetch('https://kinkinterior.com/send_mail.php', {
            method: 'POST',
            body: formData
        });

        const data = await response.json();
        setResponseMessage(data.message);

        if (response.ok) {
            form.reset(); // Reset the form
        }
    } catch (error) {
        form.reset(); // Reset the form
        setResponseMessage('E-mail sent.');
    }
};

  return (
    <>
      <header className={`${isScrolled ? 'u-header-scrolled' : ''}`}>
        <div className="u-container c-header">
          <h2 className="c-header__logo">
            Kink Interior
          </h2>
          <nav>
            <ul>
              <li>
                <a href="#portfolio">Portfolio</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section className="c-hero">
        <div className="u-container c-hero__content">
          <h1 className="lato-light">
            Kink<br/>Interior
          </h1>
          <h3 className="c-hero__subtitle">
            Redefining design,<br/>embracing desire.
          </h3>
        </div>
        <img 
        src={RabbitHoleLiving1} 
        alt="Rabbit Hole Living 1" 
        className="c-hero__image"
        />
      </section>
      <section id="portfolio">
        <div className="u-container">
          <h2>
            Our  work
          </h2>
          <div className="c-portfolio">
            <Carousel projects={projects} />
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="u-container">
          <h2>
            Contact us
          </h2>
          <div className="c-contact">
            <form className='c-contact__form' onSubmit={handleSubmit}>
              <div className="c-contact__form-fieldset">
                <label htmlFor="fullName">Full Name</label>
                <input required type="text" id="fullName" name="fullName" />
              </div>
              <div className="c-contact__form-fieldset">
                <label htmlFor="email">Email</label>
                <input required type="email" id="email" name="email" />
              </div>
              <div className="c-contact__form-fieldset">
                <label htmlFor="message">Message</label>
                <textarea required id="message" name="message"></textarea>
              </div>
              <div style={{ display: 'none' }}>
                <label htmlFor="distraction">Distraction:</label>
                <input type="text" id="distraction" name="distraction" />
              </div>
              <button className="c-contact__form-button" type="submit">Submit</button>
              {responseMessage && <span className='c-contact__success'>{responseMessage}</span>}
            </form>
          </div>
        </div>
      </section>
      <footer>
        <div className="u-container c-footer">
          <h2 className="c-footer__title">
            Kink Interior
          </h2>
        </div>
      </footer>
    </>
  );
}

export default App;
