import React, { useState } from 'react';
import './Carousel.css';

const Carousel = ({ projects }) => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);

  const handleProjectClick = (index) => {
    setSelectedProjectIndex(index);
  };

  return (
    <div className="carousel">
      <div className="carousel__container">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`carousel__item ${index === selectedProjectIndex ? 'active' : ''}`}
            onClick={() => handleProjectClick(index)}
          >
            <img src={project.image} alt={project.title} className="carousel__image" />
            <div className="carousel__text">{project.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
